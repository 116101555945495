<template>
  <div class="data-box">
    <van-nav-bar
      :title="$store.getters.getOPDetails.numName"
      left-text="返回"
      right-text=""
      left-arrow
      fixed
      z-index="1000"
      placeholder
      safe-area-inset-top
      @click-left="onClickLeft"
    />
    <div class="data-box-body" v-show="showStatus === 0">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">酒店联系人</span>
          <span class="span-name align-right">{{
            hotelTwo.hotelContacts
          }}</span>
          <a
            :href="'tel:' + hotelTwo.hotelPhone"
            class="iconfont icon-dianhua1"
          ></a>
        </div>
        <div class="body-div-item">
          <span class="span-head">剩余可住人数</span>
          <span class="span-name align-right">{{
            hotelTwo.hotelSurplus
              ? hotelTwo.hotelSurplus + "人"
              : hotelTwo.hotelSurplus
          }}</span>
        </div>
      </div>
      <div class="body-div">
        <div
          class="body-div-item"
          v-for="(item, index) in liveKeyValueList"
          :key="index"
          v-show="item.isShow"
        >
          <div class="span-key">{{ item.key }}</div>
          <div
            class="span-input"
            @click="editLiveInfo(index)"
            :style="{ background: item.disable ? '#edeef0' : '' }"
          >
            <span :class="[item.value ? '' : 'placeholder']">{{
              item.value ? item.value : "请选择" + item.key
            }}</span>
            <i :class="[item.icon, 'iconfont']"></i>
          </div>
        </div>
        <div
          class="body-div-item"
          style="align-items: flex-start"
          v-show="liveKeyValueList[3].isShow"
        >
          <div class="span-key span-top">入住要求</div>
          <div class="span-input">
            <van-field
              v-model="liveInfo.remark"
              rows="3"
              type="textarea"
              maxlength="200"
              placeholder="请输入入住要求"
              show-word-limit
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-body" v-show="showStatus === 1 || showStatus === 2">
      <div class="body-div">
        <!-- 通知退房入住人员切换 -->
        <div class="body-div-item">
          <div class="span-key">{{ liveKeyValueList[0].key }}</div>
          <div class="span-input" @click="editLiveInfo(0)">
            <span :class="[liveKeyValueList[0].value ? '' : 'placeholder']">{{
              liveKeyValueList[0].value
                ? liveKeyValueList[0].value
                : "请选择" + liveKeyValueList[0].key
            }}</span>
            <i :class="[liveKeyValueList[0].icon, 'iconfont']"></i>
          </div>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-title">入住结果</div>
        <div
          class="body-div-item"
          v-for="(item, index) in resultKeyValueList"
          :key="index"
          v-show="item.status <= showStatus"
        >
          <div class="span-key span-line">{{ item.key }}</div>
          <div class="span-value">
            <span>{{ item.value }}</span>
            <a
              v-if="
                item.key == '酒店联系人' ||
                item.key == '同房人员' ||
                item.key == '入住经办人'
              "
              :href="'tel:' + item.phone"
              class="iconfont icon-dianhua1"
            ></a>
          </div>
        </div>
      </div>
      <div class="body-div" v-show="showStatus === 1">
        <div class="body-div-item">
          <div class="span-key">退房日期</div>
          <div class="span-input" @click="editLiveInfo(2, '通知退房')">
            <span>{{ checkOutTime }}</span>
            <i class="iconfont icon-riqix"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <!-- <van-button round block type="info" @click="liveNotice(showStatus)" v-show="showStatus !== 1" ref="checkInBtn">
        {{ showStatus === 0 ? '通知入住' : "安排入住" }}
      </van-button> -->
      <van-button
        round
        block
        type="info"
        @click="liveNotice(showStatus)"
        v-show="showStatus == 0"
        ref="checkInBtn"
      >
        通知入住
      </van-button>

      <van-button
        round
        block
        type="danger"
        @click="liveNotice(showStatus)"
        v-show="showStatus === 1"
      >
        通知退房
      </van-button>
    </div>
    <van-action-sheet
      v-model="showLivePerson"
      :actions="livePersonActions"
      @select="selectLiveInfo"
    />
    <van-action-sheet
      v-model="showLiveHotel"
      :actions="liveHotelActions"
      @select="selectLiveInfo"
    />
    <van-action-sheet
      v-model="showLiveRoom"
      :actions="liveRoomActions"
      @select="selectLiveInfo"
    />
    <van-popup v-model="showCheckInDate" position="bottom">
      <van-datetime-picker
        v-model="liveInfo.checkInDate"
        type="date"
        title="入住日期"
        :min-date="liveInfo.minDate"
        :max-date="liveInfo.maxDate"
        @confirm="
          ($event) => {
            confirmDate($event, 1);
          }
        "
        @cancel="
          ($event) => {
            cancelDate($event, 1);
          }
        "
      />
    </van-popup>
    <van-popup v-model="showCheckOutDate" position="bottom">
      <van-datetime-picker
        v-model="liveInfo.checkOutDate"
        type="date"
        title="退房日期"
        :min-date="liveInfo.minDate"
        :max-date="liveInfo.maxDate"
        @confirm="
          ($event) => {
            confirmDate($event, 2);
          }
        "
        @cancel="
          ($event) => {
            cancelDate($event, 2);
          }
        "
      />
    </van-popup>
  </div>
</template>

<script>
import {
  canSelectStayFoodGrantPersons,
  addActivityObjectCanSelectHotelRoom,
  stayArrangeShow,
  informCheckInOut,
  activityDetail,
  getActivityHotel,
} from "@/api/index";
export default {
  name: "orgLive",
  data() {
    return {
      iid: "", // 保存人员id
      hotelId: "", //保存住宿酒店ID
      roomType: "", //保存入住房型
      houseTypeId: 0, // 房型ID
      personnelPhone: "", // 保存人员电话
      showStatus: 0, //0：未入住；1：已入住；2：已退房
      signInStatus: 0, // 保存签到状态 0-待进行 1-已签到 2-已签退
      liveKeyValueList: [
        { key: "入住人员", value: "", icon: "icon-bianji2", isShow: true },
        {
          key: "入住日期",
          value: "",
          icon: "icon-riqix",
          disable: true,
          isShow: false,
        },
        {
          key: "退房日期",
          value: "",
          icon: "icon-riqix",
          disable: true,
          isShow: false,
        },
        {
          key: "入住酒店",
          value: "",
          icon: "icon-bianji2",
          disable: true,
          isShow: false,
        },
        {
          key: "入住房型",
          value: "",
          icon: "icon-bianji2",
          disable: true,
          isShow: false,
        },
      ],
      resultKeyValueList: [
        { key: "入住酒店", value: "", phone: "", status: 1 },
        { key: "入住房型", value: "", phone: "", status: 1 },
        { key: "酒店联系人", value: "", phone: "", status: 1 },
        { key: "剩余可住人数", value: "", phone: "", status: 2 },
        { key: "入住时间", value: "", phone: "", status: 1 },
        { key: "入住经办人", value: "", phone: "", status: 1 },
        { key: "安排房号", value: "", phone: "", status: 1 },
        { key: "退房时间", value: "", phone: "", status: 2 },
        { key: "退房经办人", value: "", phone: "", status: 2 },
        { key: "同房人员", value: "", phone: "", status: 1 },
      ],

      checkOutTime: "", // 通知退房的退房日期

      //酒店联系人和剩余可住人数字段
      hotelTwo: {
        hotelContacts: "",
        hotelPhone: "",
        hotelSurplus: 0,
      },
      showLivePerson: false,
      livePersonActions: [],
      showLiveHotel: false,
      liveHotelActions: [],
      showLiveRoom: false,
      liveRoomActions: [],
      selectLiveType: 0,
      showCheckInDate: false,
      showCheckOutDate: false,
      liveInfo: {
        person: "",
        hotel: "",
        room: "",
        checkInDate: null,
        checkOutDate: null,
        minDate: new Date(),
        maxDate: new Date(2025, 10, 1),
        remark: "",
      },
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods: {
    initData() {
      let that = this;
      that.getCanSelectStayFoodGrantPersons();
      if (that.$store.getters.getSelectLivePersonId) {
        that.iid = that.$store.getters.getSelectLivePersonId;
        that.getStayArrangeShow("人员切换");
        that.getCanSelectStayFoodGrantPersons();
      }
    },
    // 获取组织人住宿安排界面展示
    getStayArrangeShow(type) {
      let that = this;
      if (type == "人员切换") {
        let params = {
          ayId: that.iid, // 人员ID
          companyId: that.$store.getters.getCompanyId, // 公司ID
        };
        stayArrangeShow(params).then((res) => {
          that.$tool.resValidation(res, () => {
            if (res.data.status == 0) {
              that.showStatus = 0;
              that.getActivityDetail(that.iid);
              that.liveKeyValueList.forEach((item) => {
                item.isShow = true;
              });
            } else if (res.data.status == 1) {
              that.showStatus = 1;
              that.myGetActivityHotel();
              (that.hotelId = res.data.aoscsList[0].hotelDiningId), // 入住酒店id
                (that.resultKeyValueList[4].value =
                  res.data.aoscsList[0].stayDate); // 入住时间
              that.resultKeyValueList[5].value =
                res.data.aoscsList[0].handlePerson; // 入住经办人
              that.resultKeyValueList[5].phone =
                res.data.aoscsList[0].handlePhone; // 经办人电话

              that.resultKeyValueList[6].value =
                res.data.aoscsList[0].roomEnergyFood; // 安排房号

              that.resultKeyValueList.length = 10;
              // 同房人员,如果有多个时处理
              res.data.togetherManInfo.forEach((item, index, arr) => {
                let arr2 = item.split(",");
                if (index >= 1) {
                  that.resultKeyValueList.splice(9 + index, 0, {
                    key: "同房人员",
                    value: arr2[0],
                    phone: arr2[1],
                    status: 1,
                  });
                } else {
                  that.resultKeyValueList[9].value = arr2[0];
                  that.resultKeyValueList[9].phone = arr2[1];
                }
              });
              that.houseTypeId = res.data.aoscsList[0].num1; //保存房型ID
            } else if (res.data.status == 2) {
              that.showStatus = 2;
              (that.hotelId = res.data.aoscsList[0].hotelDiningId), // 入住酒店id
                (that.resultKeyValueList[3].value =
                  res.data.residueNum + " 人"); // 剩余可住人数
              that.resultKeyValueList[4].value = res.data.aoscsList[0].stayDate; // 入住时间
              that.resultKeyValueList[5].value =
                res.data.aoscsList[0].handlePerson; // 入住经办人
              that.resultKeyValueList[5].phone =
                res.data.aoscsList[0].handlePhone; // 经办人电话

              that.resultKeyValueList[6].value =
                res.data.aoscsList[1].roomEnergyFood; // 安排房号
              that.resultKeyValueList[7].value = res.data.aoscsList[1].stayDate; // 退房时间
              that.resultKeyValueList[8].value =
                res.data.aoscsList[1].handlePerson; // 退房经办人
              that.resultKeyValueList[8].value =
                res.data.aoscsList[1].handlePhone; // 退房经办人电话

              // 同房人员,如果有多个时处理
              res.data.togetherManInfo.forEach((item, index, arr) => {
                let arr2 = item.split(",");
                if (index >= 1) {
                  that.resultKeyValueList.splice(9 + index, 0, {
                    key: "同房人员",
                    value: arr2[0],
                    phone: arr2[1],
                    status: 1,
                  });
                } else {
                  that.resultKeyValueList[9].value = arr2[0];
                  that.resultKeyValueList[9].phone = arr2[1];
                }
              });

              that.myGetActivityHotel();
            }
          });
        });
        that.$store.commit("setSelectLivePersonId", that.iid);

        that.$tool.getActObjInfo(that.iid, (res) => {
          that.signInStatus = res.status; // 保存签到状态 0-待进行 1-已签到 2-已签退
        });
      }

      if (type == "房型切换") {
        // that.liveHotelActions.forEach(item => {
        //   if(item.name == that.liveKeyValueList[3].value) {
        //     that.hotelId = item.id
        //   }
        // })
        // console.log(that.liveHotelActions)
        let arr = [];
        let arr2 = [];
        let obj = {};
        that.liveHotelActions.forEach((item) => {
          console.log(item);
          if (item.name == that.liveKeyValueList[3].value) {
            arr = item.str1.split(",");
            arr2 = item.useObjTypeStr.split(",");
            arr.forEach((item2, index) => {
              obj[item2] = arr2[index];
            });
          }
        });
        console.log(that.liveHotelActions);
        that.houseTypeId = obj[that.liveKeyValueList[4].value];

        let params = {
          ayId: that.iid, // 人员ID
          companyId: that.$store.getters.getCompanyId, // 公司ID
          hotelId: that.hotelId ? that.hotelId : "", // 酒店ID
          roomType: that.roomType ? that.roomType : "", // 房型
        };
        stayArrangeShow(params).then((res) => {
          that.$tool.resValidation(res, () => {
            that.hotelTwo.hotelContacts = res.data.acp.linkManPostName; // 酒店联系人
            that.hotelTwo.hotelPhone = res.data.acp.linkPhone; // 酒店联系人电话
            that.hotelTwo.hotelSurplus = res.data.residueNum; // 剩余可住人数
          })
        });
      }
    },

    // 获取通知退房相关信息
    myGetActivityHotel() {
      let that = this;
      let params = {
        ayId: that.iid,
      };
      getActivityHotel(params).then((res) => {
        that.$tool.resValidation(res, () => {
          that.resultKeyValueList[0].value = "";
          that.resultKeyValueList[0].phone = "";
          that.resultKeyValueList[1].value = "";
          that.resultKeyValueList[1].phone = "";
          that.resultKeyValueList[2].value = "";
          that.resultKeyValueList[2].phone = "";
          if (!res.data) return;
          let obj = JSON.parse(res.data.noteNameDateStop);
          if (that.showStatus == 1) {
            (that.liveInfo.remark = obj.checkInRequire), // 入住要求
              (that.checkOutTime = obj.checkOutDate);
            that.resultKeyValueList[2].value = obj.hotelContact; // 酒店联系人
            that.resultKeyValueList[2].phone = obj.hotelPhone; // 酒店联系人电话
            that.resultKeyValueList[0].value = obj.hotelName; // 入住酒店
            that.resultKeyValueList[1].value = res.data.checkTypeNumArticle; // 入住房型
          }
          if (that.showStatus == 2) {
            that.resultKeyValueList[0].value = obj.hotelName; // 入住酒店
            that.resultKeyValueList[2].value = obj.hotelContact; // 酒店联系人
            that.resultKeyValueList[2].phone = obj.hotelPhone; // 酒店联系人电话
            that.resultKeyValueList[1].value = res.data.checkTypeNumArticle; // 入住房型
          }
        })
      });
    },

    // 获取入住人员
    getCanSelectStayFoodGrantPersons() {
      let that = this;
      let actId = that.$store.getters.getOPDetails.activityId;
      let params = {
        actId,
        activityIds: [0, 1],
        pageNum: 1,
        pageSize: 9999,
        type: 1,
        keyword: null,
      };
      canSelectStayFoodGrantPersons(params).then((item) => {
        that.$tool.resValidation(item, () => {
          let arr = [];
          item.data.records.forEach((el) => {
            // 人员到时候后端改一下那个签到状态码，为2的时候筛选一下
            el.name = el.designation + " " + el.participantNumWorkPhone;
            arr.push(el);
            if (that.$store.getters.getSelectLivePersonId) {
              if (that.$store.getters.getSelectLivePersonId == el.id) {
                that.liveKeyValueList[0].value = el.name;
              }
            }
          });
          that.livePersonActions = arr;
        })
      });
    },

    // 获取酒店和房型
    getHotel(type) {
      let that = this;
      let params = {
        actId: that.$store.getters.getOPDetails.activityId,
        // 入住时间和退房时间
        startDate: that.liveKeyValueList[1].value,
        endDate: that.liveKeyValueList[2].value,
      };
      addActivityObjectCanSelectHotelRoom(params).then((res) => {
        that.$tool.resValidation(res, () => {
          let arr = [];
          res.data.forEach((item) => {
            item.name = item.str;
            arr.push(item);
          });
          that.liveHotelActions = arr;
          if (that.liveHotelActions.length <= 0) {
            that.liveHotelActions = [
              {
                name: "该时段无可选酒店",
              },
            ];
          }
          that.liveHotelActions.forEach((item2) => {
            if (item2.str == that.liveKeyValueList[3].value) {
              that.hotelId = item2.id;
            }
          });

          if (that.liveKeyValueList[4].value) {
            if (type == "默认带出") {
              that.getStayArrangeShow("房型切换");
            }
          }
        })
      });
    },

    // 组织人通知入住或通知退房
    getInformCheckInOut(types) {
      let that = this;
      let params = null;
      if (types == "通知入住") {
        params = {
          activityHotelRequireDTO: {
            cardId: "", // 身份证号
            checkInDate: that.liveKeyValueList[1].value, // 规定的入住日期【yyyy-MM-dd】
            checkOutDate: that.liveKeyValueList[2].value, // 规定的退房日期【yyyy-MM-dd】
            checkInRequire: that.liveInfo.remark, // 入住要求
            checkOutRequire: "", // 退房要求
            phone: that.personnelPhone, // 通知的手机号
            type: 0, // 通知类型 : 0-未入住 1-已入住 2-已退房
            houseTypeId: that.houseTypeId, // 房型ID
          },
          activityId: that.iid, // 人员id
          hotelId: that.hotelId, // 入住酒店id
          houseType: that.roomType, // 房型
        };
      }
      if (types == "通知退房") {
        params = {
          activityHotelRequireDTO: {
            cardId: "", // 身份证号
            checkInDate: that.resultKeyValueList[4].value, // 规定的入住日期【yyyy-MM-dd】
            checkOutDate: that.checkOutTime, // 规定的退房日期【yyyy-MM-dd】
            checkInRequire: that.liveInfo.remark, // 入住要求
            checkOutRequire: "", // 退房要求
            phone: that.personnelPhone, // 通知的手机号0
            type: 1, // 通知类型 : 0-未入住 1-已入住 2-已退房
            houseTypeId: that.houseTypeId, // 房型ID
          },
          activityId: that.iid, // 人员id
          hotelId: that.hotelId, // 入住酒店id
          houseType: that.resultKeyValueList[1].value, // 房型
        };
      }
      informCheckInOut(params).then((res) => {
        that.$tool.resValidation(res, () => {
          if (types == "通知入住") {
            if (res.code == 0) {
              that.$toast.success("已通知入住");
            } else {
              that.$toast.fail(res.msg);
            }
          }
          if (types == "通知退房") {
            if (res.code == 0) {
              that.$toast.success("已通知退房");
            } else {
              that.$toast.fail(res.msg);
            }
          }
        })
      });
    },

    // 未入住状态时，先请求activityDetail进行回显
    getActivityDetail(id) {
      let that = this;
      let params = {
        activityId: id,
      };
      activityDetail(params).then((res) => {
        that.$tool.resValidation(res, () => {
          res.data.activitySets.forEach((item) => {
            if (item.relateType == 2) {
              that.liveKeyValueList[4].value = item.checkTypeNumArticle; // 房型
              that.roomType = item.checkTypeNumArticle;
              if (item.noteNameDateStop) {
                let arr = item.noteNameDateStop.split("~"); // 入住和退房日期
                that.liveKeyValueList[1].value = arr[0];
                that.liveKeyValueList[2].value = arr[1];
              }
            }
            if (item.relateType == 16) {
              that.liveKeyValueList[3].value = item.chargeName; // 酒店名称
            }
          });

          if (that.liveKeyValueList[0].value)
            that.liveKeyValueList[1].disable = false;
          if (that.liveKeyValueList[1].value) {
            that.liveKeyValueList[2].disable = false;
            that.liveKeyValueList[3].disable = false;
            that.liveKeyValueList[4].disable = false;
          } else {
            that.liveKeyValueList[1].disable = false;
            that.liveKeyValueList[2].disable = true;
            that.liveKeyValueList[3].disable = true;
            that.liveKeyValueList[4].disable = true;
          }
          if (that.liveKeyValueList[3].value) {
            that.getHotel("默认带出"); // 默认带出酒店联系人信息
          }
          })
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
    //入住信息 (展开数据框并显示数据) type =>（0：人员；1：酒店；2：房型；3：入住日期；4：退房日期）
    //入住信息 (展开数据框并显示数据) type =>（0：人员；1：入住日期；2：退房日期；3：酒店；4：房型）
    editLiveInfo(type, isCheckOut) {
      let that = this;
      that.selectLiveType = type;
      if (that.selectLiveType === 0) {
        //人员
        that.showLivePerson = true;
      } else if (that.selectLiveType === 1) {
        //入住日期
        if (!that.liveKeyValueList[0].value) return;
        that.showCheckInDate = true;
      } else if (that.selectLiveType === 2) {
        //退房日期
        if (isCheckOut != "通知退房") {
          if (!that.liveKeyValueList[1].value) return;
        }
        that.showCheckOutDate = true;
      } else if (that.selectLiveType === 3) {
        //入住酒店
        if (!that.liveKeyValueList[1].value) return;
        if (!that.liveKeyValueList[2].value) return;
        that.showLiveHotel = true;
      } else if (that.selectLiveType === 4) {
        //入住房型
        if (!that.liveKeyValueList[3].value) return;

        let arr = [];
        that.liveHotelActions.forEach((item) => {
          if (item.str == that.liveKeyValueList[3].value) {
            item.str1.split(",").forEach((el) => {
              arr.push({
                name: el,
              });
            });
          }
        });
        that.liveRoomActions = arr;
        that.showLiveRoom = true;
      }
    },

    //入住信息 (关闭数据框并选中数据)

    selectLiveInfo(item) {
      let that = this;
      if (that.selectLiveType === 0) {
        that.personnelPhone = item.participantNumWorkPhone;
        that.liveInfo.remark = "";
        that.resultKeyValueList.length = 10;

        that.hotelTwo.hotelContacts = ""; // 酒店联系人
        that.hotelTwo.hotelPhone = ""; // 酒店联系人电话
        that.hotelTwo.hotelSurplus = ""; // 酒店剩余可住人数

        that.iid = item.id;
        that.showLivePerson = false;
        that.liveKeyValueList[0].value = item.name;
        that.liveKeyValueList[1].disable = false;
        that.liveKeyValueList.forEach((item, index) => {
          if (index != 0) {
            item.value = "";
          }
        });

        that.getStayArrangeShow("人员切换");
      } else if (that.selectLiveType === 3) {
        that.hotelId = item.id;

        that.hotelTwo.hotelContacts = ""; // 酒店联系人
        that.hotelTwo.hotelPhone = ""; // 酒店联系人电话
        that.hotelTwo.hotelSurplus = ""; // 剩余可住人数

        that.showLiveHotel = false;
        that.liveKeyValueList[4].disable = false;
        that.liveKeyValueList[3].value = item.name;
        that.liveKeyValueList[4].value = "";
      } else if (that.selectLiveType === 4) {
        that.showLiveRoom = false;
        that.liveKeyValueList[4].value = item.name;

        that.roomType = item.name; // 保存房型
        that.getStayArrangeShow("房型切换"); // 房型切换后重新获取酒店联系人，和酒店剩余可住人数
      }
    },

    // 入住和退房日期选择后触发
    confirmDate(date, type) {
      let that = this;
      let time = that.$tool.dateFormat(date);
      if (type === 1) {
        that.liveKeyValueList[1].value = time;
        that.showCheckInDate = false;
        that.liveKeyValueList[2].disable = false;

        if (that.liveKeyValueList[2].value) {
          that.getHotel();
        }
      } else if (type === 2) {
        // 退房日期选择后发请求获取酒店信息
        that.liveKeyValueList[2].value = time;

        that.checkOutDate = time;
        that.checkOutTime = time; // 通知退房的退房日期

        that.liveKeyValueList[3].disable = false;
        that.showCheckOutDate = false;
        that.getHotel();
      }
    },
    cancelDate(date, type) {
      let that = this;
      if (type === 1) {
        that.showCheckInDate = false;
      } else if (type === 2) {
        that.showCheckOutDate = false;
      }
    },
    liveNotice(type) {
      let that = this;

      if (type == 0) {
        if (!that.liveKeyValueList[0].value) {
          return that.$toast.fail("入住人员不能为空");
        }
        if (!that.liveKeyValueList[1].value) {
          return that.$toast.fail("入住日期不能为空");
        }
        if (!that.liveKeyValueList[2].value) {
          return that.$toast.fail("退房日期不能为空");
        }
        if (!that.liveKeyValueList[3].value) {
          return that.$toast.fail("入住酒店不能为空");
        }
        if (!that.liveKeyValueList[4].value) {
          return that.$toast.fail("入住房型不能为空");
        }

        let date1 = new Date(that.liveKeyValueList[1].value);
        let date2 = new Date(that.liveKeyValueList[2].value);
        if (date2.getTime() < date1.getTime()) {
          return that.$toast.fail("退房日期必须大于入住日期");
        }
      }

      let isHead = that.$tool.identityValidation(that.iid, 2);

      if (that.showStatus === 0) {
        if (that.signInStatus != 1) {
          return that.$toast.fail("当前人员未签到，请操作签到后再通知入住");
        }
        if (isHead) {
          that.getInformCheckInOut("通知入住");
        } else {
          that.$dialog
            .confirm({
              message: "您不是该环节的负责人，确认代替执行么？",
            })
            .then(() => {
              that.getInformCheckInOut("通知入住");
            })
            .catch(() => {
              // on cancel
            });
        }
      } else if (that.showStatus === 1) {
        if (isHead) {
          that.getInformCheckInOut("通知退房");
        } else {
          that.$dialog
            .confirm({
              message: "您不是该环节的负责人，确认代替执行么？",
            })
            .then(() => {
              that.getInformCheckInOut("通知退房");
            })
            .catch(() => {
              // on cancel
            });
        }
      } else if (that.showStatus === 2) {
        // if(isHead) {
        // }else {
        //   that.$dialog.confirm({
        //     message: '您不是该环节的负责人，确认代替执行么？',
        //   }).then(() => {
        //     that.showStatus = 0
        //   }).catch(() => {
        //     // on cancel
        //   })
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.span-value {
    display: flex;
    justify-content: space-between;
}
</style>